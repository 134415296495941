import { HTTPBase } from "./httpBase";

export class EmailPost extends HTTPBase {
    
    email_info:  { [key: string]: string };

    constructor(email_info:  { [key: string]: string }) {
        super()
        this.agent_id = "123"
        this.ask_type = "contact_us"
        this.source = 'chat'
        this.sessionid = "18299485"
        this.email_info = email_info;
    }


}