{
    "chat_title":"Q11 Assisstant Checking",
    "chat_title_bar_color":"#368bcc",
    "chat_title_bar_font_color":"#ffffff",
    "chat_title_bar_logo":"https://qwhery.safuture.ca/images/q11_l.png", 
    "chat_logo":"https://qwhery.safuture.ca/images/q11_l.png", 
    "chat_default_open":"open",
    "chat_background_color":"#4b5563",
    "customer_message_background":"#3fa5f0",
    "button_color":"#ff0080",
    "contact_us_icon":"",
    
    
    "chat_agent_id":"default",
    "index_folder":"....",
    "api_url":"......" 
}