<!-- <p-accordion>
  <p-accordionTab header="Header 1">Content 1</p-accordionTab>
  <p-accordionTab header="Header 2">Content 2</p-accordionTab>

</p-accordion> -->

<div
  class="card togglr-button"
  [ngClass]="{ visited: addParentClass }"
  [class.visited]="isVisited == true"
  cdkDragHandle
>
  <div *ngIf="!showCard" cdkDrag>
    <p-card>
      <div class="chat-title d-flex">
        <div class="chat-logo">
          <div class="icon-box">

            <p-image src="{{ environment.appDeployUrl }}/assets/q11.png" alt="Image" width="250"></p-image>

            <bdi>Q11 Assistant</bdi>
          </div>
          <div class="drag" cdkDragHandle title="Drag">
            <span class="material-symbols-outlined"> drag_indicator </span>
          </div>
        </div>
        <div class="chat-actions" title="Side by Side Display">
          <div class="action" (click)="toggleParentClass()">
            <span class="material-symbols-outlined"> team_dashboard </span>
          </div>
        
          <div class="action" (click)="checkVisited()" title="Bigger Window">
            <span class="material-symbols-outlined"> open_in_full </span>
          </div>
          <div class="action" title="Minimize">
            <p-toggleButton
              [(ngModel)]="showCard"
              onIcon="pi pi-minus"
              offIcon="pi pi-minus"
            >
            </p-toggleButton>
          </div>
        </div>
      </div>

      <p-tabView [(activeIndex)]="index" class="vertical-tabview" (onChange) = "onTabChange($event)" >
        <p-tabPanel
          class="chat-window"
          [ngClass]="{ expanded: addParentClass }">
          <ng-template pTemplate="header">
            <span class="material-symbols-outlined" title="Chat"> chat_bubble </span>
          </ng-template>

          <!-- Main Chat area -->
          <div class="min-chat">
            <!-- Admin chat -->
            <div class="chatting-wrapper" #chattingWrapper>
              <div class="welcome-chat">
                <div class="admin-chat">
                  <p-image
                    src="{{ environment.appDeployUrl }}/assets/q11.png"
                    alt="Image"
                    width="250"
                  ></p-image>
                  <bdi>
                    <strong>Welcome to Q11</strong>
                    <p>
                      Connecting smart homes with smart cities. For us to
                      provide you accurate localized info, we need to know your
                      address.
                    </p>
                  </bdi>
                </div>
                <!-- Admin chat -->

                <!-- Admin chat -->
                <div class="admin-chat">
                  <p-image
                    src="{{ environment.appDeployUrl }}/assets/q11.png"
                    alt="Image"
                    width="250"
                  ></p-image>
                  <bdi>
                    <p>What can i do for you today?</p>
                  </bdi>
                </div>
                <!-- Admin chat -->

                <!-- Suggestions -->

                <div class="card flex align-items-center suggestion-box">
                  <p-chip>
                    <span class="ml-2 font-medium"> Set up alarm</span>
                  </p-chip>

                  <p-chip>
                    <span class="ml-2 font-medium"
                      >What information can i find?</span
                    >
                  </p-chip>

                  <p-chip>
                    <span class="ml-2 font-medium"> Cange the user logo</span>
                  </p-chip>

                  <p-chip>
                    <span class="ml-2 font-medium">
                      Help me search the map</span
                    >
                  </p-chip>
                </div>

                <!-- Suggestions -->

                <!-- User chat -->
                <div class="user-chat">
                  <bdi>
                    <p>Next garbage collection date?</p>
                  </bdi>
                  <span class="material-symbols-outlined user-icon">
                    person
                  </span>
                </div>
                <!-- User chat -->

                <!-- User chat -->
                <div class="admin-chat">
                     <p-image
                    src="{{ environment.appDeployUrl }}/assets/q11.png"
                    alt="Image"
                    width="250"
                  ></p-image>
                  <bdi class="not-chat">
                    <div class="queries">
                      <p-accordion [activeIndex]="0">
                        <p-accordionTab class="activated">
                          <ng-template pTemplate="header">
                            <span
                              class="flex align-items-center gap-2 w-full s-chat"
                            >
                              <p-image
                                src="{{ environment.appDeployUrl }}/assets/q11.png"
                                alt="Image"
                              ></p-image>
                              <span class="title"
                                >Welcome to the Q11 Virtual Assistant</span
                              >
                            </span>
                          </ng-template>
                          <p>
                            The Q11 virtual assistant is availabie 24 hours a
                            day. 7 days a week to answer vour questions about
                            our community, To get started, please ente your
                            address.
                          </p>
                        </p-accordionTab>

                        <p-accordionTab
                          header="Click here to enter your address."
                        >
                          <b>loose Leaf Collection Map</b>
                          <div>
                            <img src="{{ environment.appDeployUrl }}/assets/map.png" />
                          </div>
                        </p-accordionTab>
                        <p-accordionTab header="Click here for help with Q11.">
                          <div class="schedule">
                            <b>Collection Schedule</b><br />
                            <small
                              >Loose leaves will be collected from designated
                              areas in Oakville as follows:</small
                            >
                            <ul>
                              <li>Nctober 23 to 27: Zones 1, 2, 3 and 7</li>
                              <li>Nctober 27 to 30: Zones 5, 4, 6</li>
                              <li>November 1 to 10: Zones 8, 9, 4 and 8</li>
                              <li>November 10 to 17: Zones 1, 2, 3 and 7</li>
                              <li>November 18 to 25: Zones 3, 4, 5 and 6</li>
                            </ul>
                          </div>
                        </p-accordionTab>
                      </p-accordion>
                    </div>
                  </bdi>
             
                </div>
                <!-- User chat -->

                <!-- User chat -->
                <div class="admin-chat">
                  <p-image
                  src="{{ environment.appDeployUrl }}/assets/q11.png"
                  alt="Image"
                  width="250"
                ></p-image>
                  <bdi class="not-chat">
                    <div class="queries-card">
                      <b>loose Leaf Collection Map</b>
                      <div>
                        <img src="{{ environment.appDeployUrl }}/assets/map.png" />
                      </div>
                    </div>
                  </bdi>
           
                </div>
                <!-- User chat -->
              </div>

              <!-- New chat -->
              <div class="welcome-chat" #welcomeChat>
                <!-- User messages will be dynamically added here -->
              </div>
              <!-- New chat -->
            </div>
            <!-- Main Chat area -->

            <div class="typing-area">
              <div class="typing" [ngClass]="{ active: isTyping }" title="Typing...">
                <p-image src="{{ environment.appDeployUrl }}/assets/typing-bubble.gif" alt="Image"></p-image>
              </div>
              <textarea
                id="admin-typing"
                rows="3"
                placeholder="Ask something..."
                [(ngModel)]="adminMessage"
                (keydown.enter)="onTyping($event)"
                (input)="onInput($event)"
                (blur)="onBlur()"
                (keydown.enter)="onEnterPressed()"
              ></textarea>
            </div>
          </div>
          <div class="chat-expand">
            <div class="chat-item">
              <div class="queries">
                <p-accordion [activeIndex]="0">
                  <p-accordionTab class="activated">
                    <ng-template pTemplate="header">
                      <span class="flex align-items-center gap-2 w-full">
                        <p-image src="{{ environment.appDeployUrl }}/assets/q11.png" alt="Image"></p-image>
                        <span class="title"
                          >Welcome to the Q11 Virtual Assistant</span
                        >
                      </span>
                    </ng-template>
                    <p>
                      The Q11 virtual assistant is availabie 24 hours a day. 7
                      days a week to answer vour questions about our community,
                      To get started, please ente your address.
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="Click here to enter your address.">
                    <b>loose Leaf Collection Map</b>
                    <div>
                      <img src="{{ environment.appDeployUrl }}/assets/map.png" />
                    </div>
                  </p-accordionTab>
                  <p-accordionTab header="Click here for help with Q11.">
                    <div class="schedule">
                      <b>Collection Schedule</b><br />
                      <small
                        >Loose leaves will be collected from designated areas in
                        Oakville as follows:</small
                      >
                      <ul>
                        <li>Nctober 23 to 27: Zones 1, 2, 3 and 7</li>
                        <li>Nctober 27 to 30: Zones 5, 4, 6</li>
                        <li>November 1 to 10: Zones 8, 9, 4 and 8</li>
                        <li>November 10 to 17: Zones 1, 2, 3 and 7</li>
                        <li>November 18 to 25: Zones 3, 4, 5 and 6</li>
                      </ul>
                    </div>
                  </p-accordionTab>
                </p-accordion>
              </div>

              <p-card class="chat-card">
                <ng-template pTemplate="header">
                  <p-image src="{{ environment.appDeployUrl }}/assets/q11.png" alt="Image"></p-image>
                </ng-template>
                <b>My Q11 Virtual Assistant</b>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Inventore sed consequuntur error repudiandae numquam deserunt
                  quisquam repellat libero asperiores earum nam nobis, culpa
                  ratione quam perferendis esse, cupiditate neque quas!
                </p>
              </p-card>
            </div>
          </div>
        </p-tabPanel>

        <p-tabPanel class="white">
          <ng-template pTemplate="header">
            <span class="material-symbols-outlined" title="Settings"> settings </span>
          </ng-template>
          <!-- Settings panel -->
          <div class="inner-panel">
            <h3>Settings</h3>

            <p-accordion class="scrollable-part">
              <p-accordionTab>
                <ng-template pTemplate="header">
                  <span class="flex align-items-center gap-10 w-full">
                    <i class="pi pi-user"></i>
                    <span class="acc-heading">Account</span>
                  </span>
                </ng-template>
                <p class="m-0">Account Menu</p>
              </p-accordionTab>
              <p-accordionTab>
                <ng-template pTemplate="header">
                  <span class="flex align-items-center gap-2 w-full">
                    <i class="pi pi-bell"></i>
                    <span class="acc-heading">Notifications</span>
                  </span>
                </ng-template>
                <p class="m-0">Notifications Menu</p>
              </p-accordionTab>

              <div class="location-part">
                <div class="location-header">
                  <i class="pi pi-map-marker"></i>
                  <span class="acc-heading">Location</span>
                  <p-inputSwitch [(ngModel)]="showLocation"></p-inputSwitch>
                </div>

                <div class="location" *ngIf="showLocation">
                  <p>
                    1434 Oxford Ave, Oakville ON, L6H 1t4
                    <a href="#">Change location</a>
                  </p>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2896.0874923810557!2d-79.71477972490207!3d43.458770564985954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b5d15d4046633%3A0x2337437b9669b7d5!2s1434%20Oxford%20Ave%2C%20Oakville%2C%20ON%20L6H%201T4%2C%20Canada!5e0!3m2!1sen!2sin!4v1712925638872!5m2!1sen!2sin"
                    width="100%"
                    height="180"
                    style="border: 0"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </p-accordion>
          </div>
          <!-- Settings panel -->
        </p-tabPanel>

        <p-tabPanel  >
          <ng-template pTemplate="header">
            <span class="material-symbols-outlined" title="Help"> help </span>
          </ng-template>
          <!-- Settings panel -->
          <div class="inner-panel">
            <h3>Help</h3>

            <p-accordion>
              <p-accordionTab header="Question 1 here">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </p-accordionTab>
              <p-accordionTab header="Question 2 here">
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                  quia voluptas sit aspernatur aut odit aut fugit, sed quia
                  consequuntur magni dolores eos qui ratione voluptatem sequi
                  nesciunt. Consectetur, adipisci velit, sed quia non numquam
                  eius modi.
                </p>
              </p-accordionTab>
              <p-accordionTab header="Question 3 here">
                <p>
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui
                  blanditiis praesentium voluptatum deleniti atque corrupti quos
                  dolores et quas molestias excepturi sint occaecati cupiditate
                  non provident, similique sunt in culpa qui officia deserunt
                  mollitia animi, id est laborum et dolorum fuga. Et harum
                  quidem rerum facilis est et expedita distinctio. Nam libero
                  tempore, cum soluta nobis est eligendi optio cumque nihil
                  impedit quo minus.
                </p>
              </p-accordionTab>
              <p-accordionTab header="Question 4 here">
                <p>
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui
                  blanditiis praesentium voluptatum deleniti atque corrupti quos
                  dolores et quas molestias excepturi sint occaecati cupiditate
                  non provident, similique sunt in culpa qui officia deserunt
                  mollitia animi, id est laborum et dolorum fuga. Et harum
                  quidem rerum facilis est et expedita distinctio. Nam libero
                  tempore, cum soluta nobis est eligendi optio cumque nihil
                  impedit quo minus.
                </p>
              </p-accordionTab>
              <p-accordionTab header="Question 5 here">
                <p>
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui
                  blanditiis praesentium voluptatum deleniti atque corrupti quos
                  dolores et quas molestias excepturi sint occaecati cupiditate
                  non provident, similique sunt in culpa qui officia deserunt
                  mollitia animi, id est laborum et dolorum fuga. Et harum
                  quidem rerum facilis est et expedita distinctio. Nam libero
                  tempore, cum soluta nobis est eligendi optio cumque nihil
                  impedit quo minus.
                </p>
              </p-accordionTab>
            </p-accordion>
          </div>
          <!-- Settings panel -->
        </p-tabPanel>


        <p-tabPanel [disabled]="true">

          <div class = "inner-panel">
            <h3> Contact Us </h3>
            <form [formGroup] = "contactUsForm">
              <p  class = "contact-us-fonts" [ngStyle]="{'color': contactUsCaption == contactUsInfo || contactUsCaption == contactUsSent ? '#626262' : '#ef2d40' }" [innerText]="contactUsCaption"></p>
              <input class = "contact-us-fonts" formControlName="name" style = "width: 100%; border: 1px solid black; border-radius: 2px;" placeholder="First Name">
              <input [ngStyle]="{'border': emailErrors ? '2px solid #ef2d40' : '1px solid black', 'border-radius' : '2px'}" formControlName="email" class = "contact-us-form-elements  contact-us-fonts" name="email" ngModel email  type= "email" style = "width: 100%"  placeholder="Email Address*">
              <div class = "error-message" *ngIf="emailErrors">
                *Please enter a valid email address.
              </div>
              <div class = "contact-us-form-elements  contact-us-fonts" style = "position: relative;">
                <textarea formControlName="message" class = "contact-us-fonts"  style = "width: 100%; height:40vh; resize: none" placeholder="Enter Your Question*"  [maxlength]="characterMax"
                [ngStyle]="{'border': messageErrors ? '2px solid #ef2d40' : '1px solid black', 'border-radius' : '2px'}"
                ></textarea>
                <div> 
                  <div style="position: absolute; right: 2px; font-size: 10px; color: #666;">Characters Remaining: {{charRemaining}}</div>
                  <div class = "error-message" *ngIf="messageErrors">
                    *Please enter a question.
                  </div>
                </div>
              </div>
              <div class = "contact-us-form-elements" style = "text-align :right;">
                <button [disabled] = "contactUsLoading" (click)="contactUs()" style = "margin-top: 20px; font-size: 15px; color : {{curAgent.chat_title_bar_font_color}}; padding: 10px 30px 10px 30px; border:0; border-radius: 50px; background-color: {{curAgent.chat_title_bar_color}}"> Send </button>
              </div>

            </form>

            
          </div>
        </p-tabPanel>



        
      </p-tabView>

      <div class="contact-us" [ngStyle]="{
        'background-color': index == 3 ? '#efefef' : 'rgba(0,0,0,0)'}">
        <button (click)="switchToTab(4)">
          <span [ngStyle]="{'color': index == 3 ? '#333333' : '#b3b3b3'}" class="material-symbols-outlined" id = 'contact-Us' title="Contact Us"> mail </span>
        </button>
      </div>
    </p-card>
  </div>
  <div class="button-wrap">
    <p-toggleButton
      [(ngModel)]="showCard"
      onIcon="pi pi-comment"
      offIcon="pi pi-times"
      [style]="{ width: '4em', height: '4em', borderRadius: '100px' }"
      
    >
    </p-toggleButton>
  </div>

  <router-outlet />
</div>
