import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from './../environments/environment';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
// import { RouterModule } from '@angular/router'; 
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { SpeedDialModule } from 'primeng/speeddial';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { CardModule } from 'primeng/card';
import { TabViewModule, TabViewChangeEvent, TabView } from 'primeng/tabview';
import { ImageModule } from 'primeng/image';
import { ChipModule } from 'primeng/chip';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AccordionModule } from 'primeng/accordion';
import { SidebarModule } from 'primeng/sidebar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ResponseGeneric } from './http/responseGeneric';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';


import { HttpClientModule, HttpClient } from '@angular/common/http';
import { EmailPost } from './http/emailPost';

import * as agent from '../assets/agents/default-q11.json'
import { error } from 'node:console';


@Component({
  selector: 'app-q11bot',
  standalone: true,
  imports: [ CommonModule,
  FormsModule,
  DragDropModule, 
  ButtonModule,
  ToastModule,
  SpeedDialModule,
  ToggleButtonModule,
  CardModule,
  TabViewModule,
  ImageModule,
  ChipModule,
  InputTextareaModule,
  TooltipModule,
  AccordionModule,
  SidebarModule, InputSwitchModule, HttpClientModule, FormsModule, ReactiveFormsModule,],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})


export class AppComponent implements OnInit {
  curAgent: any;
  askChatEndPoint = 'rf-aibot/askchat/'
  activeTabIndex = 0;
  characterMax = 1500;
  contactUsForm: FormGroup;
  contactUsMessageError = false;
  contactUsEmailError = false;
  contactUsInfo  = "Enter your email and question below";
  contactUsSent = "Email Sent!"
  contactUsCaption = this.contactUsInfo;
  contactUsLoading = false;
  index = 0;
  


  constructor(private http: HttpClient, private formbuilder:FormBuilder) {
    this.contactUsForm = this.formbuilder.group({
      email: ['', [Validators.required, Validators.email]],
      message:  ['', [Validators.required]],
      name: [''],
    })
    
  }

  ngOnInit() {
    this.curAgent = agent
    if (this.curAgent.chat_default_open == "open") {
      this.showCard = true
    }
  }



  adminMessage: string = ''; 

  @ViewChild('welcomeChat') welcomeChat: ElementRef | undefined;
  @ViewChild('tabView') tabView: TabView | undefined;
  @ViewChild('chattingWrapper') chattingWrapper: ElementRef | undefined;



  title = 'chat';
  showCard: boolean = true;
  showCard1: boolean = true;

  value!: string;


  sidebarVisible: boolean = false;
  checked: boolean = false;


  showLocation: boolean = false;

  environment = environment;

  addParentClass: boolean = false;
  addTopParentClass: boolean = false;

  toggleParentClass() {
    this.addParentClass = !this.addParentClass;
    this.addTopParentClass = !this.addTopParentClass;
    this.isVisited = !this.isVisited;

  }

  public isVisited = false;

  public checkVisited() {
     this.isVisited = !this.isVisited;
     this.addTopParentClass = !this.addTopParentClass;

  }

  get charRemaining(): number {
    return this.characterMax -  this.contactUsForm.get('message')?.value.length || 0
  }

  get messageErrors(): boolean {
    return this.contactUsForm.get('message')?.errors && (this.contactUsForm.get('message')?.dirty || this.contactUsForm.get('message')?.touched) || this.contactUsMessageError
  }

  get emailErrors(): boolean {
    return this.contactUsForm.get('email')?.errors && (this.contactUsForm.get('email')?.dirty || this.contactUsForm.get('email')?.touched) || this.contactUsEmailError

  }


  onTyping(event: any) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.scrollToBottom(); 
      this.sendMessage(); 
    }
  }

  switchToTab(tabIndex: number) {
    this.index = 3
  }

  sendMessage() {
    if (this.adminMessage.trim() !== '') {
      const welcomeChatDiv = this.welcomeChat?.nativeElement;
      if (welcomeChatDiv) {
        // Create the elements
        const userChatDiv = document.createElement('div');
        userChatDiv.className = 'user-chat';

        const bdiElement = document.createElement('bdi');
        const pElement = document.createElement('p');
        pElement.innerText = this.adminMessage; // Set the message content

        const spanElement = document.createElement('span');
        spanElement.className = 'material-symbols-outlined';
        spanElement.innerText = 'person';

        // Append elements
        bdiElement.appendChild(pElement);
        userChatDiv.appendChild(bdiElement);
        userChatDiv.appendChild(spanElement);
        welcomeChatDiv.appendChild(userChatDiv);

        const isScrolledToBottom = welcomeChatDiv.scrollHeight - welcomeChatDiv.clientHeight <= welcomeChatDiv.scrollTop + 1;

        // Scroll to the bottom only if already at the bottom
        if (isScrolledToBottom) {
          setTimeout(() => { // Use setTimeout to ensure the scroll happens after the new content is rendered
            welcomeChatDiv.scrollTop = welcomeChatDiv.scrollHeight;
          });
        }
  
        this.adminMessage = ''
      }
    }
  }

  scrollToBottom() {
    const chattingWrapperDiv = this.chattingWrapper?.nativeElement;
    if (chattingWrapperDiv) {
      chattingWrapperDiv.scrollTop = chattingWrapperDiv.scrollHeight;
    }
  }


  isTyping: boolean = false;

  onInput(event: Event): void {
    this.isTyping = (event.target as HTMLInputElement).value.length > 0;
  }
  

  onBlur(): void {
    this.isTyping = false;
  }

  onEnterPressed(): void {
    this.isTyping = false;
  }

  onTabChange(event: TabViewChangeEvent) {
    const index = event.index
    if (index != 3) {
      this.contactUsCaption = this.contactUsInfo
      this.clearForm()
    }
  }

  clearForm() {
    this.contactUsForm.markAsPristine()
    this.contactUsForm.markAsUntouched()
    this.contactUsForm.patchValue({
      email: '',
      name: '',
      message: '',
    })
  }

  contactUs() {
    this.contactUsLoading = true
    if (!this.contactUsForm.get('email')?.errors && !this.contactUsForm.get('message')?.errors) {
      const url = environment.api + this.askChatEndPoint
      const emailInfo = { 
        "email": this.contactUsForm.get('email')?.value ,
        "message": this.contactUsForm.get('message')?.value,
        "name": this.contactUsForm.get('name')?.value || '',
      }
      const data = new EmailPost(emailInfo)
      const observe = this.http.post<ResponseGeneric>(url, data )
      this.contactUsForm.get('email')?.value
  
      observe.subscribe(
        resData => {
          if (resData.responseinerror == 'n') {
            this.contactUsCaption = this.contactUsSent
            this.clearForm()
            this.contactUsLoading = false
          } else {
            this.contactUsCaption = "Sorry, Some Issue Happened. Please Try Again Later!"
            this.contactUsLoading = false
          }
        },
        () => {
          this.contactUsCaption = "Sorry, Some Issue Happened. Please Try Again Later!"
          this.contactUsLoading = false

      })
    } else {
      if (this.contactUsForm.get('message')?.errors) {
        this.contactUsMessageError = true
      }
      if (this.contactUsForm.get('email')?.errors) {
        this.contactUsEmailError = true
      }
      setTimeout(() => {
        this.contactUsMessageError = false
        this.contactUsEmailError = false
      }, 2000)
      this.contactUsLoading = false
    }
  }


    

}

  



