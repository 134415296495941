
export class HTTPBase {

    agent_id?: string;
    sessionid?:string;
    source?:string;
    ask_type? :string; 

}

//declare these varibale in here using Session and Keys